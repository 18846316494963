import React, { useContext } from "react";
import Loader from "react-loader-spinner";
import AuthContext from "../../services/AuthContext";

export default function Loading({ color = false }) {
  const context = useContext(AuthContext);

  return (
    <div style={{ textAlign: "center", height: "100vh", marginTop: "12%" }}>
      <Loader
        type="TailSpin"
        color={color ? color : 'blue'}
        height={80}
        width={80}
      />
    </div>
  );
}
