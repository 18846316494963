import React, { useContext } from 'react';
import AuthContext from '../../services/AuthContext';


export default function Widget(props){
    const context = useContext(AuthContext);
    return(
        <>
            <div className="report-counter" style={{background:props.bg}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <p className="report-title" style={{color: 'white'}}>{props.title}</p>
                  </div>
                </div>
                <p style={{color: 'white'}} className="count">{props.count}</p>
              </div>
            </div>
        </>
    )
}